import { Text } from '@workos-inc/component-library';
import { Card } from 'components/card';
import { Confirm } from 'components/confirm';
import { FileField, TextField } from 'components/fields';
import { Form } from 'components/form';
import { Img } from 'components/image';
import { CopyInput } from 'components/input';
import { ManualMetadataStep } from 'components/sso/steps/saml/manual-metadata-step';
import { Article, Title } from 'components/typography';
import { ConnectionStepProps } from 'interfaces/step-props';
import React, { useState } from 'react';
import { useFeature } from 'utils/feature-flags';

export const Auth0CreateApplication: React.FC<
  Readonly<ConnectionStepProps>
> = ({ appName, connection, onNextStep }) => (
  <Article>
    <Title className="mb-4">Step 1: Create SAML Application</Title>

    <Text>
      In your Auth0 Dashboard, select Applications {'>'} Applications from the
      sidebar. Then select "Create Application".
    </Text>

    <Img priority height={556} src="/sso/auth0/v1/auth0-1.png" width={1475} />

    <Text>
      Enter an "Application Name" that describes {appName}, select the "Regular
      Web Applications" type, then click "Create".
    </Text>

    <Img priority height={1240} src="/sso/auth0/v1/auth0-2.png" width={1596} />

    <Text>
      Select the "Addons" tab in your new application, and then open the SAML2
      WEB APP menu.
    </Text>

    <Img height={412} src="/sso/auth0/v1/auth0-3.png" width={1030} />

    <CopyInput label="Copy this ACS URL" value={connection?.saml_acs_url} />

    <Text>
      Enter the ACS URL provided above in the "Application Callback URL" input
      box.
    </Text>

    <Img height={1328} src="/sso/auth0/v1/auth0-4.png" width={1274} />

    <Text>
      Scroll down to the bottom of the AddOn modal and select "Enable".
    </Text>

    <Img height={1784} src="/sso/auth0/v1/auth0-5.png" width={1276} />

    <Confirm
      label="I've configured the ACS URL in the SAML application."
      onClick={onNextStep}
    />
  </Article>
);

export const Auth0UploadMetadata: React.FC<Readonly<ConnectionStepProps>> = (
  connectionStepProps,
) => {
  const {
    connection,
    errors,
    isLoading,
    onFileInput,
    onInputChange,
    onNextStep,
    validationErrors,
  } = connectionStepProps;

  const [selectedConfiguration, setSelectedConfiguration] = useState('dynamic');

  const isInputSamlCertificateMetadataUrlEnabled = useFeature(
    'inputSamlCertificateMetadataUrl',
  );
  const isUseGeneratedConnectionEntityIdEnabled = useFeature(
    'useGeneratedConnectionEntityId',
  );

  if (!isInputSamlCertificateMetadataUrlEnabled) {
    return (
      <Article>
        <Title className="mb-4">
          Step 2: Upload Identity Provider Metadata
        </Title>

        <Text>
          On the Usage tab of the Addon menu, you are given an overview of your
          Identity Provider metadata.
        </Text>

        <Img
          priority
          height={1320}
          src="/sso/auth0/v1/auth0-6.png"
          width={1268}
        />

        {!isUseGeneratedConnectionEntityIdEnabled && (
          <Text>
            Copy the "Issuer" to use as the Identity Provider Issuer below.
          </Text>
        )}

        <Text>
          Copy the "Identity Provider Login URL" to use as the Identity Provider
          Single Sign-On URL below.
        </Text>

        <Text>
          Download the "Identity Provider Certificate" to use as the X.509
          Certificate below.
        </Text>

        <Card>
          <Form
            disabled={
              !connection?.saml_idp_url ||
              !connection?.saml_x509_certs ||
              !connection?.saml_entity_id ||
              validationErrors?.saml_idp_url?.value === connection?.saml_idp_url
            }
            isLoading={isLoading}
            isUpdate={
              !!(
                errors?.saml_idp_url ||
                errors?.saml_entity_id ||
                errors?.saml_x509_certs
              )
            }
            onSubmit={onNextStep}
          >
            <TextField
              autoFocus={true}
              error={errors?.saml_idp_url || validationErrors?.saml_idp_url}
              label="1. Identity Provider Single Sign-On URL"
              name="saml_idp_url"
              onChange={onInputChange}
              placeholder="https://foo-corp.okta.com/app/exkgq2/sso/saml"
              value={connection?.saml_idp_url}
            />
            {isUseGeneratedConnectionEntityIdEnabled ? (
              <FileField
                error={errors?.saml_x509_certs}
                filename="X.509 Certificate"
                label="2. X.509 Certificate"
                name="saml_x509_certs"
                onUpload={onFileInput}
                value={connection?.saml_x509_certs?.[0]}
              />
            ) : (
              <>
                <TextField
                  error={errors?.saml_entity_id}
                  label="2. Identity Provider Issuer"
                  name="saml_entity_id"
                  onChange={onInputChange}
                  placeholder="http://www.okta.com/exkgq2c19CUpt2Brr46"
                  value={connection?.saml_entity_id}
                />

                <FileField
                  error={errors?.saml_x509_certs}
                  filename="X.509 Certificate"
                  label="3. X.509 Certificate"
                  name="saml_x509_certs"
                  onUpload={onFileInput}
                  value={connection?.saml_x509_certs?.[0]}
                />
              </>
            )}
          </Form>
        </Card>
      </Article>
    );
  }
  return (
    <Article>
      <Title className="mb-4">Step 2: Upload Identity Provider Metadata</Title>

      <Text>
        The final step for implementing SAML SSO requires sharing your identity
        provider's metadata with the application.
      </Text>

      {selectedConfiguration === 'dynamic' && (
        <>
          <Text as="h2" size="large" weight="medium">
            URL Metadata Configuration
          </Text>

          <Text>
            On the application's Settings page, scroll down to the bottom and
            expand the "Advanced Settings" section. Select the "Endpoints" tab
            and copy the SAML Metadata URL and enter it in the field below.
          </Text>

          <Img
            priority
            height={817}
            src="/sso/auth0/v1/auth0-metadata-url.png"
            width={846}
          />
          <Card>
            <Form
              disabled={!connection?.saml_idp_metadata_url}
              isLoading={isLoading}
              isUpdate={!!errors?.saml_idp_metadata_url}
              onSubmit={onNextStep}
              secondaryAction={() => setSelectedConfiguration('manual')}
              secondaryText="Configure Manually"
            >
              <TextField
                error={errors?.saml_idp_metadata_url}
                label="IdP Metadata URL"
                name="saml_idp_metadata_url"
                onChange={onInputChange}
                placeholder="https://"
                value={connection?.saml_idp_metadata_url}
              />
            </Form>
          </Card>
        </>
      )}

      {selectedConfiguration === 'manual' && (
        <ManualMetadataStep
          {...connectionStepProps}
          idpEntityIdPlaceholder="https://auth.workos.com/AbC123..."
          idpSsoUrlPlaceholder="https://mycompany.us.auth0.com/samlp/AbC123..."
          onConfigurationTypeChange={setSelectedConfiguration}
        />
      )}
    </Article>
  );
};
